<template>
  <el-dialog
    title="提醒"
    :show-close="true"
    :visible="true"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <span>{{ content }}</span>
    <span slot="footer" class="dialog-footer">
      <div class="flex justify-end" style="gap: 12px">
        <el-button plain @click="$emit('close')">返回</el-button>
        <router-link :to="{name: 'MemberProfile', params: {id: member.id}}">
          <el-button v-loading="redirect" type="primary" :disabled="redirect" @click="redirect = true">{{ redirect ? '頁面跳轉中' : '前往查看' }}</el-button>
        </router-link>
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MemberExistDialog',
  props: {
    content: {
      type: String,
      default: '此手機號碼已於系統註冊，請前往查看。',
    },
    width: {
      type: String,
      default: '40%',
    },
    member: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    redirect: false,
  }),
}
</script>

<style lang="postcss" scoped>

</style>
