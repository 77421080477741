<template>
  <el-dialog
    title="提醒"
    :show-close="true"
    :visible="true"
    :width="width"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <span>系統將同步提供資料至{{ org.name }}分店 - {{ shopConfig.name }}。</span>
    <span slot="footer" class="dialog-footer">
      <div class="flex justify-end" style="gap: 12px">
        <el-button plain @click="$emit('close')">返回</el-button>
        <el-button v-loading="loading" type="primary" @click="registerShopMemberByUser">前往查看</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { RegisterShopMemberByUser } from '@/api/member'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SyncMemberDialog',

  props: {
    content: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '40%',
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(['shop']),
    ...mapState('org', ['org']),
    ...mapState('shop', ['shopConfig']),
  },
  methods: {
    async registerShopMemberByUser () {
      this.loading = true
      const [res, err] = await RegisterShopMemberByUser({
        shopId: this.shop,
        userId: this.user.id,
      })
      if (err) {
        this.$message.error(err)
        this.loading = false
        return
      }
      this.$router.push({
        name: 'MemberProfile',
        params: {
          id: res.member.id,
        },
      })
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
